/*-----------------
  Main
-----------------*/

.ant-layout-content {
    margin-left: 80px;
}

@media only screen and (max-width: 768px) {
    .ant-layout-content {
        margin-left: 0;
    }
}

.ant-layout-sider {
    flex: 0 0 280px !important;
    max-width: 280px !important;
    min-width: 280px !important;
    width: 280px !important;
}

.ant-layout-sider-collapsed {
    flex: 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
}

.sidebar-active {
    margin-left: 330px;
}

.trigger-toggle {
    font-size: 20px;
    cursor: pointer;
    transition: color 0.3s;
    position: fixed;
    z-index: 999;
    left: 0;
    left: 330px;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    padding-top: 25px;
    padding-left: 19px;
}

.trigger-toggle.padding-top {
    padding-top: 55px;
}

.trigger-toggle:hover {
    color: black;
}

.toggle-off {
    left: 0;
}

.account-menu {
    max-height: 350px;
    -ms-overflow-style: none;
    overflow: auto;
    width: 240px;
    border-radius: 4px;
    margin-left: 20px;
    margin-top: -15px;
    border: 1px solid #d9d9d9;
}

.account-menu .account-item {
    height: 59px;
    overflow: hidden;
    border-bottom: 1px solid #ebebeb;
}

.account-menu .account-item:hover {
    background-color: #f5f5f5;
}

.account-menu .account-item .p {
    font-weight: 600;
    color: #253238;
    margin-top: 12px;
    font-size: 15px;
    overflow: hidden;
}

.account-menu .account-item .p p {
    word-break: break-all;
}

.account-menu .account-item .logo {
    display: flex;
    border-radius: 3px;
    border: solid 1px #ebebeb;
    background-color: white;
    width: 36px;
    height: 36px;
    float: left;
    margin-right: 10px;
    margin-top: 6px;
}

.account-menu .account-item .logo img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
    margin: auto;
    border-radius: 2px;
}

.account-menu .account-item .account-plus {
    background-color: #dbdad9;
    border-radius: 3px;
    width: 36px;
    height: 36px;
    float: left;
    margin-top: 6px;
    overflow: hidden;
    margin-right: 10px;
    -ms-overflow-style: none;
}

.account-menu .account-item .account-plus p {
    text-align: center;
    color: white;
    font-size: 36px;
    line-height: 25px;
    margin-top: 5px;
    font-weight: 600;
}
