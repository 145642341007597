.header-navigation {
    position: fixed;
    top: 0;
    z-index: 900;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #e7ecf0;
    height: 71px;
}

.header-navigation.notification-visible {
    margin-top: 35px;
}

.header-navigation.ant-layout-header {
    background-color: white;
    height: 71px;
    padding: 0px 20px 0 50px !important;
}

.ant-dropdown-menu {
    padding: 0px;
}

.profile-item:last-child {
    border: none;
}

.profile-item {
    width: 160px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f2f4f8;
    color: black;
    font-weight: 600;
    font-size: 13px;
}

.profile-item.cancelled {
    color: #d0d0d0 !important;
    pointer-events: none;
}

.profile-item:hover {
    background-color: #fafafa;
}

.profile-item a {
    color: black;
    font-weight: 600;
    font-size: 13px;
}

.header-navigation .logo {
    border-radius: 50%;
    background-color: #ddd;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    overflow: hidden;
}

.header-navigation .logo img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
}

.header-navigation .support-link {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border: none;
    float: right;
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.header-navigation .support-link .icon path,
.header-navigation .support-link .icon circle {
    stroke: black;
}

.header-navigation .support-link {
    margin-right: 0.5rem;
}

.header-navigation .intercom-chat-btn {
    margin-right: 0.75rem;
}
