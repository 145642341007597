.notification-header {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    height: 35px;
    width: 100%;
}

.notification-header .message {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: white;
    margin: 0 auto;
}

.notification-header .link {
    font-weight: 600;
    font-size: 14px;
    color: white;
    text-decoration: underline;
    margin-left: 5px;
}

.notification-header .message .message-title {
    margin: 0;
}

.notification-header .close-icon {
    justify-self: flex-end;
    color: white;
    margin-right: 15px;
}

@media screen and (max-width: 768px) {
    .notification-header .message .message-title {
        line-height: 12px;
        padding: 0 5px 3px 5px;
    }
}
