@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900');

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
}

#page-loading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100vh;
}

.semi-bold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.new-donation-modal {
    overflow: hidden !important;
}

.new-donation-modal .ant-modal-content {
    padding: 0px;
    height: 100%;
}

.locked-message-modal .ant-modal-content {
    padding: 0;
}

.page-button.ant-btn {
    margin-right: 10px;
    border-radius: 3px;
}

.page-button.ant-btn:hover,
.page-button.ant-btn:focus {
    background-color: #f8fafb;
    border-color: rgb(217, 217, 217);
    color: rgba(0, 0, 0, 0.65);
}

.page-button2.ant-btn:hover,
.page-button2.ant-btn:focus {
    border-color: rgb(199, 198, 199);
    color: rgba(0, 0, 0, 0.65);
}

.ant-modal .ant-btn {
    border-radius: 3px !important;
}

.ant-btn-primary:hover,
.ant-modal .ant-btn-primary:hover {
    color: #ffffff !important;
    background-color: #286ffa !important;
    border-color: #286ffa !important;
}

.content {
    padding: 130px 2rem 2rem 2rem;
    font-size: 16px;
    background-color: #f7f8fa;
    font-weight: 400;
    overflow: auto;
    min-height: 100vh !important;
    display: flex;
    flex-direction: column;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
}

.content-bg-default {
    background-color: #f7f8fa;
}

.content.bg-white {
    background-color: #fff;
}

.content.notification-padding {
    padding: 130px 2rem 2rem 2rem !important;
}

.full-page {
    margin-left: 0 !important;
}

.inner {
    margin-top: 20px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #f2f4f8;
    padding: 40px 50px;
}

.inner .ant-tabs-bar,
.inner .ant-tabs-nav {
    margin-bottom: 5px;
    height: 34px;
    border-color: #eaeaea;
    border-bottom-width: 1px;
}

.ant-tabs-nav .ant-tabs-tab {
    font-weight: 600 !important;
    transition: all 0s ease 0s !important;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #286ffa !important;
}

.ant-tabs-ink-bar {
    background-color: #286ffa !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #699afc;
}

.inner .ant-tabs-nav .ant-tabs-tab {
    padding: 0 0px;
    margin: 0 20px 10px 0;
    font-size: 16px;
}

.inner .ant-tabs-nav .ant-tabs-tab-active:hover {
    color: #333333;
}

.inner .ant-tabs-ink-bar {
    background-color: #286ffa;
    height: 1px;
    top: 33px;
}

.inner.campaign-details {
    width: 100%;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
}

.content .logout {
    margin: 10px auto auto;
}

.ant-dropdown .members.ant-menu-root.ant-menu-vertical {
    box-sizing: border-box;
    border: 1px solid #c9d7df;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    min-width: 200px;
    padding: 15px;
}

.ant-dropdown .members .ant-menu-item {
    color: #666;
    border-radius: 3px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    height: 30px;
    margin: 0;
}

.ant-dropdown .members.ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin: 0;
}

.ant-dropdown .members .ant-menu-item:hover {
    color: #333;
    background-color: #f8f8f8;
}

.header + .inner {
    margin-top: 20px;
}

.full-page {
    margin-left: 0 !important;
}

.mobile {
    margin-left: 0 !important;
}

.hidden-content {
    visibility: hidden;
    opacity: 0;
}

/* Ends moved from routes.css */

.ant-btn.ant-btn-primary:not(.ant-btn-background-ghost) {
    font-weight: bold;
}

.ant-btn-primary.btn-hollow,
.ant-btn-primary.btn-hollow:hover {
    background-color: transparent !important;
    padding: 0 !important;
    color: #286ffa !important;
}

.ant-btn-primary.btn-hollow:hover {
    color: #286ffa !important;
}

.ant-btn-primary.btn-hollow i {
    margin-right: 2px !important;
}

.ant-tooltip .ant-tooltip-inner {
    background-color: black;
    border-radius: 3px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px 10px 10px 10px;
}

.ant-popover.ant-popover-placement-right {
    position: fixed;
}

.ant-confirm-body .ant-confirm-title {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
}

.ant-confirm-body .ant-confirm-content {
    font-size: 16px !important;
    line-height: 20px;
}

.ant-confirm-body .anticon {
    margin-top: 2px;
}

.ant-confirm.add-to-campaign .ant-confirm-btns {
    display: none;
}

.ant-confirm.add-to-campaign .ant-confirm-btns.show {
    display: block;
}

.switch-toggle .ant-form-item-label {
    padding-right: 20px;
    text-align: left;
    white-space: unset;
    line-height: unset;
}

.confirm-500 {
    width: 500px !important;
}

.blue-icon .anticon {
    color: #286ffa !important;
}

.red-icon .anticon {
    color: red !important;
}

.warning-icon .anticon {
    color: #faad14 !important;
}

.ant-calendar-picker {
    display: inline-block;
    width: 100%;
}

.confirm-delete-modal .ant-confirm-btns .ant-btn.ant-btn-primary {
    background-color: #ffffff;
    border-color: #ff0000;
    color: #ff0000;
}

.confirm-delete-modal .ant-confirm-btns .ant-btn.ant-btn-primary:hover {
    background-color: #ff0000;
    color: #ffffff;
}

.list-group {
    padding-left: 0;
    margin-bottom: 0px;
}

.list-group-item {
    list-style: none;
}

.available-badge_green {
    border-radius: 12px;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid #38ab58;
    background: #38ab58;
    color: white;
    padding: 2px 6px;
}

.available-badge_red {
    border-radius: 12px;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid red;
    background: red;
    color: white;
    padding: 2px 6px;
}

.ant-modal-content .ant-btn {
    margin-top: 0px !important;
}

.ant-modal-content .ant-btn-primary {
    width: 100%;
    border-radius: 3px;
    margin-top: 0px !important;
    font-weight: 800;
    height: 36px !important;
}

.ant-modal-footer {
    display: flex;
    justify-content: right;
    border: none !important;
}

.ant-confirm .ant-btn-primary {
    font-size: 14px !important;
    height: 32px !important;
    font-weight: 400 !important;
    width: auto !important;
}

.ant-form .ant-form-item .ant-switch {
    margin: 0 !important;
}

.ant-form .ant-form-item .ant-form-item-label label {
    font-size: 16px;
    font-weight: 600;
}

.ant-form .reverse-ordering .ant-form-item-control {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ant-form .ant-input-number {
    width: 100% !important;
}

.ant-upload {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    overflow: hidden !important;
    justify-content: center !important;
    flex-direction: column !important;
}

.ant-upload-drag,
.ant-upload-select-picture-card {
    height: 130px !important;
}

.ant-upload-drag,
.ant-upload-select-picture-card {
    height: 130px !important;
}

.ant-tabs-vertical .ant-tabs-bar {
    width: 120px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #000000 !important;
    line-height: 24px !important;
}

.ant-modal-confirm-content {
    margin-top: 8px !important;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    font-size: 14px;
    color: #474747;
    line-height: 18px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    display: flex;
    width: 100%;
    flex-direction: row;
    float: left;
    align-items: center;
    margin-top: 0px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
    margin: 0;
    order: -1;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-danger {
    margin: 0;
    order: -1;
    margin-left: 38px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-danger span {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
    background: #286ffa !important;
    border: #286ffa !important;
    color: white;
    font-weight: bold;
    margin-left: 38px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary span {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff !important;
}

.ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn-danger {
    background: red;
    border: red;
    color: white;
    font-weight: bold;
}

.ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn-danger:hover {
    color: white;
}

.form-button .ant-modal-confirm-btns .ant-btn-primary {
    background: #286ffa !important;
    border: #286ffa !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
    margin: 10px 0 0 8px;
    width: auto;
    min-width: 115px;
    display: inline-block;
    height: 30px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn:hover {
    border-color: #d9d9d9;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
    min-width: 115px !important;
    height: 30px !important;
}

/* buttons */

.blue-background {
    background-color: #286ffa !important;
    color: white;
}

.blue-text {
    color: #286ffa !important;
}

.blue-link {
    color: #286ffa;
    font-weight: 600;
    transition: all 0s ease 0s !important;
}

.blue-link:hover {
    color: #699afc !important;
    text-decoration: underline !important;
}

.ant-input,
.ant-selection,
.ant-select-selection--single {
    border-radius: 8px !important;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child,
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.gv-input .ant-input-group .ant-input,
.gv-input .ant-input-group .ant-input:focus,
.gv-input .ant-input-group .ant-input:hover,
.ant-input.gv-input,
.ant-input.gv-input:hover,
.ant-input.gv-input:focus,
.gv-autocomplete .ant-select-search__field {
    min-height: 36px;
    line-height: 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
    font-size: 14px !important;
    padding: 4px 11px;
    border: 1px solid rgb(228, 234, 236) !important;
    background-color: #fff;
    border-radius: 8px !important;
}

.gv-input .ant-input-group-addon:first-child {
    font-weight: 600;
    color: #999999 !important;
    border-radius: 3px 0 0 3px;
    min-height: 36px;
    line-height: 20px;
    box-shadow: none;
    margin-bottom: 0;
    text-align: center;
    border: none;
    padding: 0 10px 0 10px;
    font-size: 14px;
    background-color: #e4eaec !important;
    width: 36px;
}

.gv-input.url .ant-input-group-addon:first-child {
    font-weight: 600;
    color: black;
    border-radius: 3px 0 0 3px;
    min-height: 36px;
    line-height: 20px;
    box-shadow: none;
    margin-bottom: 0;
    text-align: center;
    border: none;
    padding: 0 10px 0 10px;
    font-size: 14px;
    background-color: #e4eaec;
    width: 36px;
}

.ant-popover-buttons .ant-btn.ant-btn-primary {
    background-color: #286ffa !important;
    color: white !important;
    border-color: #286ffa !important;
    border-radius: 3px;
}

.ant-popover-buttons .ant-btn,
.ant-popover-buttons .ant-btn:hover {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 3px;
}

@media (max-width: 860px) {
    #root {
        overflow: hidden;
    }

    .content {
        padding: 120px 20px 20px 20px;
    }

    .content.notification-padding {
        padding: 120px 1.5rem 1.5rem 1.5rem !important;
    }

    .ant-table-row-expand-icon {
        border-color: black !important;
        line-height: 15px !important;
    }

    .ant-table-row-collapsed::after {
        color: black;
    }

    .ant-table-row-expanded::after {
        color: black;
    }

    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}

@media (max-width: 425px) {
    .content.notification-padding {
        padding: 120px 1rem 1rem 1rem !important;
    }
}

/* Modals */

.ant-modal .ant-modal-header {
    border-radius: 12px 12px 0 0;
}

.ant-modal .ant-modal-content {
    color: #000;
    border-radius: 12px;
}

.ant-modal .ant-modal-header .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
}

.ant-modal.filter-modal {
    max-width: 340px !important;
}

.ant-modal.filter-modal .ant-modal-content {
    overflow: auto !important;
    max-height: 500px !important;
}

.ant-modal.filter-modal .ant-modal-content .ant-select {
    width: 100% !important;
}

.ant-modal.filter-modal .ant-modal-content .ant-btn-primary {
    width: auto !important;
    margin-top: 15px !important;
}

.ant-modal.create-transaction-modal {
    max-width: 560px !important;
}

.ant-modal.create-transaction-modal .ant-modal-content {
    padding: 0;
}

.ant-modal.create-transaction-modal,
.ant-modal.create-transaction-modal iframe,
.ant-modal.create-transaction-modal .ant-modal-content {
    border-radius: 8px !important;
}

/* End Modals */

/* Alerts */

.ant-alert {
    border: 0 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.ant-alert.ant-alert-no-icon {
    padding: 12px 20px !important;
}

.ant-alert-close-icon {
    top: 13px !important;
    font-size: 15px !important;
}

.ant-alert.ant-alert-error .ant-alert-message {
    color: #ff0200 !important;
}

.ant-alert.ant-alert-error {
    color: #ff0200;
    background: rgba(255, 2, 0, 0.1);
}

/* End Alerts */

/* Pagination */

.pagination-total {
    position: absolute;
    left: 0;
    color: #000;
    font-weight: 600;
    padding-left: 15px;
}

.ant-table-pagination.ant-pagination {
    margin-bottom: 0 !important;
}

/* End Pagination */

/* Filters */

.filters {
    display: flex;
    width: 100%;
}

@media (max-width: 768px) {
    .filters {
        display: block;
    }

    .filters > *:not(:last-child) {
        margin-bottom: 15px !important;
    }

    .filters > *,
    .filters > * > button {
        width: 100% !important;
        text-align: left !important;
    }
}

/* End Filters */

/* Form Elements */

.ant-form {
    color: #000 !important;
}

.ant-form-extra {
    color: #555 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    line-height: 18px !important;
}

.ant-table-expanded-row {
    background-color: #f5f5f5 !important;
}

.ant-form .reverse-ordering .ant-form-extra {
    order: 1;
    margin-top: 0;
    padding-top: 0;
}

.ant-form .reverse-ordering .ant-form-item-children {
    order: 2;
}

@media (min-width: 992px) {
    .switch-form-item .ant-form-extra {
        width: 75%;
    }
}

.ant-form-item-label {
    color: #000 !important;
    padding-bottom: 0px !important;
}

.ant-form-item-children {
    margin-top: 4px;
}

.ant-switch-checked {
    background-color: #286ffa !important;
}

.ant-checkbox-inner {
    transition: all 0s ease 0s !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    border-color: #286ffa !important;
    background-color: #286ffa !important;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover,
.ant-checkbox-checked:not(.ant-checkbox-disabled)::after,
.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
    border-color: #286ffa !important;
}

/* End Form Elements */

/* Search Field */

.search-field:not(input) {
    border: 0 !important;
}

input.search-field,
.search-field > input {
    width: 200px;
    font-weight: 600;
    transition: all 0.3s;
    color: #000 !important;
    box-shadow: 0 2px 0 #00000004;
    border: 1px solid #e4edff !important;
    background-color: #f9fbff !important;
}

input.search-field:not(:focus),
.search-field > input:not(:focus) {
    width: 115px;
}

.search-field::placeholder,
.search-field > input::placeholder {
    color: #000 !important;
}

@media (max-width: 768px) {
    .search-field,
    .search-field > input,
    input.search-field:not(:focus),
    .search-field > input:not(:focus) {
        width: 100% !important;
    }
}

/* End Search Field */

/* Dropdown Menus */

.ant-select-dropdown-menu-item {
    color: #000 !important;
    padding: 5px 20px !important;
    white-space: normal !important;
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #eaf1ff !important;
}

.ant-dropdown-menu-item,
.ant-select-dropdown-menu-item {
    transition: all 0s ease 0s !important;
}

.ant-dropdown-menu-item > button,
.ant-dropdown-menu-submenu-title > button {
    display: block;
}

/* End Dropdown Menus */

/* Popover Menus */

.ant-popover {
    min-width: 273px;
}

/* End Popover Menus */

/* End Rich Text Editors */

/* Ant Buttons */

.ant-btn {
    font-weight: 600 !important;
}

.ant-btn:not(.ant-btn-small):not(.ant-btn-menu) {
    min-width: 93px;
    font-size: 15px;
    min-height: 38px;
}

.ant-btn:not(.ant-btn-small):not(.ant-btn-menu) {
    height: 38px;
    font-size: 15px;
    min-width: 93px;
    line-height: 36px;
    border-radius: 8px;
}

.ant-btn {
    border-radius: 8px !important;
}

.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-primary:hover {
    border-color: #286ffa !important;
}

.ant-btn.ant-btn-primary:not(.ant-btn-background-ghost),
.ant-btn.ant-btn-primary:not(.ant-btn-background-ghost):focus,
.ant-modal .ant-btn.ant-btn-primary:not(.ant-btn-background-ghost) {
    color: #fff !important;
    background-color: #286ffa !important;
}

.ant-btn.ant-btn-primary.ant-btn-background-ghost,
.ant-btn.ant-btn-primary.ant-btn-background-ghost:hover {
    color: #286ffa !important;
    background-color: transparent !important;
}

.ant-btn-danger {
    border-color: #ff0200 !important;
}

.ant-btn-danger:disabled {
    color: #ff4d4f !important;
}

.ant-btn-danger:not(.ant-btn-background-ghost) {
    background-color: #ff0200 !important;
}

.ant-btn:disabled {
    opacity: 0.3;
}

.ant-btn.ant-btn-standard:hover,
.ant-btn.ant-btn-standard:focus,
.ant-btn.ant-btn-standard:active {
    color: #286ffa !important;
    border-color: #286ffa !important;
}

.ant-btn.ant-btn-success {
    color: #2ca62d !important;
    border-color: #ddf1dd !important;
    background-color: #ddf1dd !important;
}

.ant-btn:not(.ant-btn-primary):not(.ant-btn-success):not(.ant-btn-danger):not(.ant-btn-link):not(.ant-btn-custom):not(.ant-btn-standard):not(:disabled) {
    color: #000 !important;
    border: 1px solid #e4edff !important;
    background-color: #f9fbff !important;
}

.ant-btn:not(.ant-btn-primary):not(.ant-btn-success):not(.ant-btn-danger):not(.ant-btn-link):not(.ant-btn-custom):not(.ant-btn-standard):not(:disabled):hover,
.ant-btn.active:not(.ant-btn-primary):not(.ant-btn-success):not(.ant-btn-danger):not(.ant-btn-link):not(.ant-btn-custom):not(.ant-btn-standard):not(:disabled) {
    border-color: #6d9eff !important;
    background-color: #f4f6fb !important;
}

.ant-btn.ant-btn-link:not(.ant-btn-custom) {
    border: 0 !important;
    width: auto !important;
    min-width: 0 !important;
    background: none !important;
}

/* End Ant Buttons */

/* Input Fields */

.ant-switch {
    position: relative;
}

@media (min-width: 992px) {
    .ant-switch {
        top: 22px;
    }
}

.ant-checkbox-wrapper {
    color: #000 !important;
}

.ant-select-selection {
    color: #000 !important;
}

.ant-select-selection:hover {
    border-color: #d9d9d9 !important;
}

.ant-select-selection__placeholder {
    font-weight: normal !important;
}

.ant-input[placeholder='Search...']:not([role='combobox']) {
    padding: 4px 15px !important;
}

.ant-input-affix-wrapper .ant-input[placeholder='Search...'] {
    padding-left: 35px !important;
}

.ant-input-affix-wrapper .ant-input:focus[placeholder='Search...'] {
    padding-right: 35px !important;
}

.ant-input[role='combobox'],
.ant-input:not([placeholder='Search...']) {
    transition: all 0s ease 0s !important;
}

.ant-input-affix-wrapper:hover
    .ant-input:not(:focus):not([placeholder='Search...']):not(.ant-input-disabled) {
    border-color: #d9d9d9 !important;
}

.ant-time-picker-input:not(:focus):hover,
.ant-input[role='combobox']:not(:focus):hover,
.ant-input:not([placeholder='Search...']):not(:focus):hover {
    border-color: #d9d9d9 !important;
}

.ant-time-picker-input,
.ant-select-search--inline,
.ant-select-selection--single,
.ant-input:not(textarea)[role='combobox'],
.ant-input:not(textarea):not(.custom-input):not([placeholder='Search...']) {
    height: 38px !important;
}

.ant-select-search--inline {
    height: 35px !important;
}

.ant-select-selection-selected-value,
.ant-select:not(.ant-select-show-search) .ant-select-selection__placeholder {
    padding-top: 3px;
}

.ant-select-selection-selected-value {
    width: 100%;
}

.ant-select-selection--single:focus,
.ant-select-open .ant-select-selection {
    transition: all 0s ease 0s !important;
}

.ant-input:focus,
.ant-time-picker-input:focus,
.ant-select-selection--single:focus,
.ant-select-open .ant-select-selection {
    box-shadow: none !important;
    border-color: #286ffa !important;
}

textarea {
    padding: 8px 11px;
}

.ant-select {
    font-weight: 600;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px !important;
}

.ant-input-affix-wrapper .ant-input[placeholder='Search...']:not(:first-child) {
    padding-left: 35px !important;
}

.ant-input-affix-wrapper .ant-input-prefix {
    left: 0 !important;
}

.ant-input-affix-wrapper .ant-input-prefix > img {
    position: relative;
    left: 12px;
}

.ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 0 !important;
}

.ant-input-group-addon {
    color: #000000 !important;
    font-weight: 600 !important;
    background-color: #e4eaec !important;
}

.ant-upload.ant-upload-select {
    display: block !important;
}

#cover-photo .ant-upload-drag {
    height: 190px !important;
}

/* End Input Fields */

/* Ant Utilities */

.ant-card {
    box-shadow: none !important;
}

.ant-row + .ant-divider-horizontal {
    margin: 15px 0 15px 0;
}

/* End Ant Utilities */

/* GrowSurf Utilities */

.grsf-window {
    max-width: 500px !important;
}

.grsf-window-tabs {
    margin-top: 0px !important;
    border-top: 1px solid #e0e0e0 !important;
}

.grsf-global,
.grsf-share {
    max-width: 380px !important;
    margin: auto !important;
}

.grsf-global h5 {
    font-weight: 600 !important;
    font-size: 15px !important;
}

.grsf-share-button-action-text {
    font-size: 14px !important;
}

.grsf-copy-link-button {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.grsf-share-button {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.grsf-share-button .grsf-share-button-text {
    width: auto !important;
}

.grsf-social-icon {
    width: 18px !important;
    height: auto !important;
}

.grsf-share-button .grsf-social-icon {
    margin-right: 8px !important;
}

.grsf-share-rettiwt {
    margin-right: 0px !important;
}

.grsf-form-control {
    box-shadow: none !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 3px !important;
}

.grsf-button.btn-lg {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.grsf-global .grsf-widget .grsf-widget-inner {
    display: none !important;
}

/* End Growsurf Utilities */
