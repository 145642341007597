@tailwind base;
@tailwind components;
@tailwind utilities;

/**
  *  fix for the infinite pages when printing page
 **/
@media print {
    main.content {
        display: block;
        height: 100%;
        width: 100%;
        margin-left: 0 !important;
        overflow-y: hidden;
    }

    aside,
    .ant-layout aside,
    .header-navigation {
        display: none;
    }
}
