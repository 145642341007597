.splash-page {
    font-weight: 600;
    max-width: 1050px;
    margin: auto;
    box-sizing: border-box;
}

.splash-page h1 {
    margin: 0;
    color: black;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 5px;
}

.splash-page h2 {
    font-size: 18px;
    color: black;
    margin-top: 0px;
}

.splash-page p {
    font-size: 16px;
    color: black;
    margin-top: 0px;
    margin-bottom: 4px;
}

.splash-page .button-group {
    margin-top: 20px;
}

.splash-page .button-group a {
    font-size: 14px;
}

.splash-page .loading {
    background-color: #286ffa;
    width: 202px;
    border-radius: 3px;
    text-align: center;
    padding: 7px 15px;
}

.splash-page .button {
    padding: 10px 15px;
    background-color: #286ffa;
    color: white !important;
    border-radius: 3px;
    font-weight: 800;
    font-size: 15px;
    margin-top: 30px;
    margin-right: 14px;
}

.splash-page .price {
    color: #666;
    font-size: 12px !important;
    font-weight: 400;
    margin-top: 15px;
    display: inline-block;
}

.splash-page .main {
    padding-bottom: 50px;
    display: flex;
}

.splash-page .main-tabbed {
    padding-top: 50px;
    margin-left: 50px;
}

.splash-page .main .text {
    width: 50%;
    padding-right: 50px;
}

.splash-page .main-tabbed .text {
    padding-top: 10px;
}

.splash-page .main .image {
    float: left;
    width: 50%;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(179, 179, 179, 0.42);
    -moz-box-shadow: 0px 0px 6px 1px rgba(179, 179, 179, 0.42);
    box-shadow: 0px 0px 6px 1px rgba(179, 179, 179, 0.42);
}

.splash-page .main .image-plain {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 35%;
    max-width: 250px;
}

.splash-page .main .image img {
    width: 100%;
    border-radius: 3px;
}

.splash-page .plus-features {
    font-size: 15px;
    font-weight: 400;
}

.splash-page .plus-features a {
    margin-right: 5px;
}

.splash-page .plus-heading {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 800;
}

.splash-pop {
    background-color: white;
    width: 100%;
    height: 500px;
}

.splash-pop .mast {
    width: 100%;
    height: 70px;
    overflow: auto;
    background-color: #ffbd00;
}

.splash-pop .mast img {
    display: block;
    margin: auto;
    height: 28px;
    margin-top: 20px;
}

.splash-pop .button-group {
    margin: auto;
    text-align: center;
}

.splash-pop .button {
    margin-right: 0px;
}

.splash-pop .text-box {
    width: 100%;
    box-sizing: border-box;
    padding: 40px;
    margin: auto;
}

.splash-pop .text-box-inner {
    margin: auto;
    height: auto;
    overflow: auto;
    width: 100%;
    max-width: 585px;
}

.splash-pop h1 {
    font-size: 26px;
    margin-bottom: 30px;
    text-align: center;
}

.splash-pop p {
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .splash-page .main {
        flex-direction: column;
    }

    .splash-page .main .text {
        width: 100%;
        order: 2;
        padding-right: 0px;
    }

    .splash-page .main .image {
        margin-bottom: 25px;
    }
}
